<template>
  <form class="relative" @submit.prevent="onSubmit">
    <label class="mb-2.5 block text-sm text-white" for="signUpForSpecialOffers">
      {{ $t("signUpForSpecialOffers") }}:
    </label>
    <div class="mb-2.5">
      <UiInput
        id="signUpForSpecialOffers"
        v-model="userEmail"
        class="w-full placeholder:capitalize"
        name="email"
        :hide-details="false"
        :placeholder="$t('emailAddress')"
      />
    </div>
    <div class="mb-7 text-13_16 font-250 text-white">
      {{ $t("byClickingSignUpYouAgreeToOur") }}
      <nuxt-link
        :to="localePath('/terms-of-service')"
        class="underline hover:no-underline"
      >
        {{ $t("termsOfService") }}
      </nuxt-link>
    </div>
    <UiButton
      :loading="loadingEmail"
      type="submit"
      color="secondary"
      class="w-full uppercase"
    >
      {{ $t("signUp") }}
    </UiButton>
    <div
      v-if="emailSend"
      class="absolute right-0 top-[65px] w-full bg-white px-3 py-2 text-sm lg:w-[280px]"
    >
      {{ $t("weVeSentAnEmailToTheProvidedAddress") }}
    </div>
  </form>
</template>

<script setup lang="ts">
import { object, string } from "yup";
import { useForm } from "vee-validate";
import { FetchError } from "ofetch";
interface InitialForms {
  email: string;
}

const { $api } = useNuxtApp();
const { t } = useI18n();
const route = useRoute();
const localePath = useLocalePathPolyfill();

const userEmail = ref("");
const emailSend = ref(false);
const loadingEmail = ref<boolean>(false);

const initialForm: InitialForms = {
  email: "",
};

const { generateLeadEvent } = useEvents();

const schema = object().shape({
  email: string().email().required().label(t("email")),
});

const formValues = reactive(
  JSON.parse(JSON.stringify(initialForm)),
) as InitialForms;

const { handleSubmit, resetForm, setErrors, errors } = useForm({
  initialValues: formValues,
  validationSchema: schema,
});

watch(
  () => route.fullPath,
  () => {
    emailSend.value = false;
  },
);

watch(
  errors,
  (val) => {
    if (val.email) {
      emailSend.value = false;
    }
  },
  { deep: true },
);

const onSubmit = handleSubmit(async (values) => {
  loadingEmail.value = true;
  try {
    await $api.general.subscribe(values);
    emailSend.value = true;
    userEmail.value = "";
    generateLeadEvent("subscribe");
    resetForm();
    setTimeout(() => {
      emailSend.value = false;
    }, 5000);
  } catch (error) {
    console.error(error);
    if (error instanceof FetchError) {
      setErrors(responseErrorToObjectErrors(error?.data?.errors));
    }
  } finally {
    loadingEmail.value = false;
  }
});
</script>
